import React from "react"
import { Link } from "gatsby"

import Img from "gatsby-image"

// export default props => (
//   <article
//     className={`post-card ${props.count % 3 === 0 && `post-card-large`} ${
//       props.postClass
//     } ${props.node.frontmatter.thumbnail ? `with-image` : `no-image`}`}
//     style={
//       props.node.frontmatter.thumbnail && {
//         backgroundImage: `url(${props.node.frontmatter.thumbnail.childImageSharp.fluid.src})`,
//       }
//     }
//   >
//     <Link to={props.node.fields.slug} className="post-card-link">
//       <div className="post-card-content">
//         <h2 className="post-card-title">
//           {props.node.frontmatter.title || props.node.fields.slug}
//         </h2>
//       </div>
//     </Link>
//   </article>
// )

export default props => (
  <Link
    to={props.node.fields.slug}
    className="spotlight"
    style={{ textDecoration: "none" }}
  >
    {/* <section className="spotlight"> */}
    <div className="image">
      <img
        //   src={`url(${props.node.frontmatter.thumbnail})`}
        // src={require(`${props.node.frontmatter.logoPath}`)}
        // src={pic1}
        src={props.node.frontmatter.thumbnail.childImageSharp.fluid.src}
        alt=""
      />
    </div>

    <div className="content">
      <h2>
        {props.node.frontmatter.title || props.node.fields.slug}
        <br />
      </h2>
      <p>{props.node.frontmatter.description}</p>
    </div>
    {/* </section> */}
  </Link>
)
